import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import Login from './screens/AuthArea/Login';
import PasswordRecovery from 'screens/AuthArea/PasswordRecovery';
import CustomerRegistration from 'screens/AuthArea/CustomerRegistration';

import MyPolicies from './screens/ClientArea/MyPolicies';
import PolicyDetails from 'screens/ClientArea/PolicyDetails';
import ProposalDetails from 'screens/ClientArea/ProposalDetails';
import PolicyClaim from 'screens/ClientArea/PolicyClaim';
import NewClaim from 'screens/ClientArea/NewClaim';
import ClaimDetails from 'screens/ClientArea/ClaimDetails';
import FinancialStatement from 'screens/ClientArea/FinancialStatement';
import Checkout from 'screens/ClientArea/Checkout';
import Doubt from 'screens/ClientArea/Doubt';
import PrintPolicy from 'screens/ClientArea/PrintPolicy';
import AgentRegistration from 'screens/AgentArea/AgentRegistration';
import PrintProposal from 'screens/ClientArea/PrintProposal';
import Wallet from 'screens/ClientArea/Wallet';

import BackofficePolicyList from 'screens/BackofficeArea/PolicyList';
import BackofficePolicyDetails from 'screens/BackofficeArea/PolicyDetails';
import BackofficePrintPolicy from 'screens/BackofficeArea/PrintPolicy';
import BackofficePrintProposal from 'screens/BackofficeArea/PrintProposal';
import ListDocumentsPolicy from 'screens/BackofficeArea/ListDocumentsPolicy';
import ChargesListBackoffice from './screens/BackofficeArea/ChargeList';
import BackofficeAgentDetails from 'screens/BackofficeArea/AgentDetails';
import BackofficeComplaint from 'screens/BackofficeArea/PolicyComplaint';
import BackofficeProposalList from 'screens/BackofficeArea/ProposalList';
import BackofficeAgentList from 'screens/BackofficeArea/AgentList';
import BackofficeProposalDetails from 'screens/BackofficeArea/ProposalDetails';
import BackofficeUserList from 'screens/BackofficeArea/UserList';
import BackofficeUserDetails from 'screens/BackofficeArea/UserDetails';
import BackofficeComplaintList from 'screens/BackofficeArea/ComplaintList';
import BackofficeComplaintDetails from 'screens/BackofficeArea/ComplaintDetails';
import BackofficeNewComplaint from 'screens/BackofficeArea/NewComplaint';
import BackofficeInvoice from 'screens/BackofficeArea/Invoice';
import BackofficeInvoiceProposal from 'screens/BackofficeArea/Invoice/InvoiceProposal';
import BackofficeCancelList from 'screens/BackofficeArea/CancelList';
import BackofficeClaimList from 'screens/BackofficeArea/ClaimList';
import BackofficeClaimDetails from 'screens/BackofficeArea/ClaimDetails';
import BackofficeClaimHistory from 'screens/BackofficeArea/ClaimHistory';
import BackofficeClaimFile from 'screens/BackofficeArea/ClaimFile';
import BackofficeThirdPartyFile from 'screens/BackofficeArea/ThirdPartyFile';
import BackofficeSettings from 'screens/BackofficeArea/Settings';
import BackofficeDashboards from 'screens/BackofficeArea/Dashboards';
import BackofficePolicyHistory from 'screens/BackofficeArea/PolicyHistory';
import BackofficeProviders from 'screens/BackofficeArea/ProvidersList';
import BackofficeBillet from 'screens/BackofficeArea/Invoice/Billet';
import BackofficeBillets from 'screens/BackofficeArea/Financial/Billets';
import BackofficeBilletProposal from 'screens/BackofficeArea/Invoice/BilletProposal';
import BackofficeDefaulters from 'screens/BackofficeArea/Financial/Defaulters';
import BackofficeBilletPolicy from 'screens/BackofficeArea/Invoice/BilletPolicy';
import BackofficeSupportList from 'screens/BackofficeArea/SupportList';
import BackofficeSupportDetails from 'screens/BackofficeArea/SupportDetails';
import ExportPremiumServices from 'screens/BackofficeArea/ExportPremiumServices';
import DownloadReports from 'screens/BackofficeArea/Assistance/DownloadReports';
import AssistanceList from 'screens/BackofficeArea/Assistance/AssistanceList';

import MyProfile from 'screens/ClientArea//Profile';

import { Billet } from 'screens/ClientArea/Billet';
import BackOfficeProductsPartners from 'screens/BackofficeArea/Products/Partners';
import BackOfficeProductsPartnersComission from 'screens/BackofficeArea/Products/PartnersComission';
import BackOfficeProductsBlacklistQuotation from 'screens/BackofficeArea/Products/BlacklistQuotation';
import BackOfficeProductsBlockCoverages from 'screens/BackofficeArea/Products/BlockCoverages';
import BackOfficeProductsManageZipcodes from 'screens/BackofficeArea/Products/ManageZipcodes';
import BackOfficeProductsManageVehicles from 'screens/BackofficeArea/Products/ManageVehicles';
import BackOfficeProductsCoverages from 'screens/BackofficeArea/Products/Coverages';

const handleClickHelpButton = () => {
  window.open('https://splitriskseguradora.atlassian.net/servicedesk/customer/portal/2', '_blank');
};

const PublicRoute = ({ component: Component, path, ...rest }) => {
  // Não mostra o botão nas telas de impressão
  const showHelpButton = !path?.includes('imprimir');

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          Cookies.get('splitrisk-authtoken') ? (
            <Redirect to={{ pathname: '/minhas-apolices', state: { from: props.location } }} />
          ) : (
            <Component {...props} />
          )
        }
      />
      {showHelpButton && (
        <div class="question-widget" onClick={handleClickHelpButton}>
          <span class="question-icon">?</span>
        </div>
      )}
    </>
  );
};

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  // Não mostra o botão nas telas de impressão
  const showHelpButton = !path?.includes('imprimir');

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          Cookies.get('splitrisk-authtoken') ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
      {showHelpButton && (
        <div class="question-widget" onClick={handleClickHelpButton}>
          <span class="question-icon">?</span>
        </div>
      )}
    </>
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* Auth */}
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/recuperar-senha" component={PasswordRecovery} />
      <PublicRoute exact path="/cadastro-cliente" component={CustomerRegistration} />
      <PublicRoute path="/cadastro-corretor" component={AgentRegistration} />
      {/* Client Area */}
      <PrivateRoute exact path="/minhas-apolices" component={MyPolicies} />
      <PrivateRoute exact path="/detalhes-da-apolice/:id" component={PolicyDetails} />
      <PrivateRoute exact path="/meus-sinistros/:id" component={PolicyClaim} />
      <PrivateRoute exact path="/novo-sinistro/:id" component={NewClaim} />
      <PrivateRoute exact path="/sinistro/:id" component={ClaimDetails} />
      <PrivateRoute exact path="/detalhes-da-apolice/:id/imprimir" component={PrintPolicy} />
      <PrivateRoute exact path="/detalhes-da-proposta/:id/imprimir" component={PrintProposal} />
      <PrivateRoute exact path="/detalhes-da-proposta/:id" component={ProposalDetails} />
      <PrivateRoute exact path="/minhas-faturas/:id" component={FinancialStatement} />
      <PrivateRoute exact path="/duvidas" component={Doubt} />
      <PrivateRoute exact path="/meu-perfil" component={MyProfile} />
      <PrivateRoute exact path="/minha-carteira" component={Wallet} />
      <PrivateRoute exact path="/pagamento/:id" component={Checkout} />
      <PrivateRoute exact path="/pagamentos/boleto" component={Billet} />
      {/* Backoffice Area*/}
      <PrivateRoute exact path="/backoffice/apolices" component={BackofficePolicyList} />
      <PrivateRoute exact path="/backoffice/detalhes-da-apolice/:id/imprimir" component={BackofficePrintPolicy} />
      <PrivateRoute exact path="/backoffice/listar-documentos-apolice/:id" component={ListDocumentsPolicy} />
      <PrivateRoute exact path="/backoffice/detalhes-da-apolice/:id" component={BackofficePolicyDetails} />
      <PrivateRoute exact path="/backoffice/cobrancas" component={ChargesListBackoffice} />
      <PrivateRoute exact path="/backoffice/historico-da-apolice/:id" component={BackofficePolicyHistory} />
      <PrivateRoute exact path="/backoffice/faturas/:id" component={BackofficeInvoice} />
      <PrivateRoute exact path="/backoffice/boletos-proposta/:id" component={BackofficeBilletProposal} />
      <PrivateRoute exact path="/backoffice/faturas-proposta/:id" component={BackofficeInvoiceProposal} />
      <PrivateRoute exact path="/backoffice/boleto/:id" component={BackofficeBillet} />
      <PrivateRoute exact path="/backoffice/propostas" component={BackofficeProposalList} />
      <PrivateRoute exact path="/backoffice/corretores" component={BackofficeAgentList} />
      <PrivateRoute exact path="/backoffice/detalhes-do-corretor/:id" component={BackofficeAgentDetails} />
      <PrivateRoute exact path="/backoffice/detalhes-da-proposta/:id" component={BackofficeProposalDetails} />
      <PrivateRoute exact path="/backoffice/detalhes-da-proposta/:id/imprimir" component={BackofficePrintProposal} />
      <PrivateRoute exact path="/backoffice/usuarios" component={BackofficeUserList} />
      <PrivateRoute exact path="/backoffice/detalhes-do-usuario/:id" component={BackofficeUserDetails} />
      <PrivateRoute exact path="/backoffice/reclamacoes" component={BackofficeComplaintList} />
      <PrivateRoute exact path="/backoffice/historico-reclamacao/:id" component={BackofficeComplaint} />
      <PrivateRoute exact path="/backoffice/detalhes-reclamacao/:id" component={BackofficeComplaintDetails} />
      <PrivateRoute exact path="/backoffice/abrir-reclamacao/:id" component={BackofficeNewComplaint} />
      <PrivateRoute exact path="/backoffice/parceiros" component={BackofficeProviders} />
      <PrivateRoute exact path="/backoffice/sinistros" component={BackofficeClaimList} />
      <PrivateRoute exact path="/backoffice/detalhes-do-sinistro/:id" component={BackofficeClaimDetails} />
      <PrivateRoute exact path="/backoffice/enviar-arquivo/:id" component={BackofficeClaimFile} />
      <PrivateRoute exact path="/backoffice/terceiro-arquivo/:id" component={BackofficeThirdPartyFile} />
      <PrivateRoute exact path="/backoffice/historico-sinistro/:id" component={BackofficeClaimHistory} />
      <PrivateRoute exact path="/backoffice/boletos" component={BackofficeBillets} />
      <PrivateRoute exact path="/backoffice/inadimplentes" component={BackofficeDefaulters} />
      <PrivateRoute exact path="/backoffice/cancelamentos" component={BackofficeCancelList} />
      <PrivateRoute exact path="/backoffice/configuracoes" component={BackofficeSettings} />
      <PrivateRoute exact path="/backoffice/dashboard" component={BackofficeDashboards} />
      <PrivateRoute exact path="/backoffice/boletos-apolice/:id" component={BackofficeBilletPolicy} />
      <PrivateRoute exact path="/backoffice/suporte" component={BackofficeSupportList} />
      <PrivateRoute exact path="/backoffice/suporte/:id" component={BackofficeSupportDetails} />
      <PrivateRoute exact path="/backoffice/assistencia/baixar-relatorios" component={DownloadReports} />
      <PrivateRoute exact path="/backoffice/assistencia/" component={AssistanceList} />
      <PrivateRoute exact path="/backoffice/premio-servicos/" component={ExportPremiumServices} />
      {/* Backoffice Area  - Products*/}
      {/* <PrivateRoute exact path="/backoffice/produtos/parceiros" component={BackOfficeProductsPartners} />
      <PrivateRoute exact path="/backoffice/produtos/parceiros-comissao" component={BackOfficeProductsPartnersComission} />
      <PrivateRoute exact path="/backoffice/produtos/bloqueio-cotacoes" component={BackOfficeProductsBlacklistQuotation} />
      <PrivateRoute exact path="/backoffice/produtos/bloqueio-coberturas" component={BackOfficeProductsBlockCoverages} />
      <PrivateRoute exact path="/backoffice/produtos/administrar-ceps" component={BackOfficeProductsManageZipcodes} />{' '}
      <PrivateRoute exact path="/backoffice/produtos/administrar-veiculos" component={BackOfficeProductsManageVehicles} />
      <PrivateRoute exact path="/backoffice/produtos/coberturas" component={BackOfficeProductsCoverages} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
