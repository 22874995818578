/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { loadingColor, white } from 'styles/colorProvider';
import { Container, LoadingArea, ButtonArea, Printable } from './styles';
import { formatCPF, formatCNPJ, formatPlate, formatPhone, formatZipCode, formatCurrency } from 'react-data-formatter';
import { Button } from 'components';
import { BsPrinter } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { formatDocument } from 'react-data-formatter';
import { cpf } from 'cpf-cnpj-validator';

function BackofficePrintQuotation({ location, history }) {
  const proposalId = parseInt(location.pathname.split('/')[3]);
  const [proposalData, setProposalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const loadData = async () => {
    const proposalNumber = {
      method: 'GET',
      url: `/proposal/${proposalId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };
    setLoading(true);

    const response = await api(proposalNumber);
    let valueService = 0;
    let valueCoverage = 0;
    let hasCoverages = false;
    let hasServices = false;
    for (const item of response.data?.arrayUnique || []) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }
    if (response.data?.products) {
      for (const item of response.data?.products || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }
    if (response.data?.coverages) {
      for (const item of response.data?.coverages || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }

    if (response?.data?.coverages?.length) {
      hasCoverages = true;
      setValueCoverage(response?.data?.price?.total_premium);
      if (response?.data) response.data.iof = response?.data?.price?.iof;
    } else {
      setValueCoverage(valueCoverage);
    }

    setProposalData(response.data);

    setHasCoverages(hasCoverages);
    setHasServices(hasServices);
    setLoading(false);
    setValueService(valueService);
  };

  useEffect(() => loadData(), []);

  const isCpf = cpf.isValid(proposalData?.user?.cpf);

  return (
    <>
      {!loading && (
        <ButtonArea>
          <Button style={{ color: white }} height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <div className="header">
              <div className="logoArea">
                <img src={proposalData?.logo || ''} />
              </div>
              <div className="titleArea">
                <h2>
                  PROPOSTA DE SEGURO AUTOMÓVEL
                  <br /> Processo Susep: 15414.618377/2020-87
                </h2>
              </div>
            </div>

            <section>
              <h2 className="descriptionheader">
                Esse documento contém todas as informações simplificadas sobre a sua proposta do seu seguro.{' '}
              </h2>

              <div className="columns">
                <div className="leftArea">
                  <h4>Protocolo</h4>
                  <p className="highlight">{proposalData?.protocol}</p>
                </div>
                <div className="rightArea">
                  <h4>Data de envio da proposta</h4>
                  <p className="dateInfo">{moment(proposalData?.created_at).format('DD/MM/YYYY [às]  HH:mm')}</p>
                  {/* <h4>Status da proposta</h4>
                                    <p className="dateInfo">{proposalData?.status}</p> */}
                </div>
              </div>
            </section>

            <section>
              <h1>Dados do segurado</h1>
              <h4>Segurado: {proposalData?.user?.name?.toUpperCase()}</h4>
              {isCpf && <h4>Nome social: {proposalData?.user?.social_name?.toUpperCase()}</h4>}
              <h4>CPF/CNPJ: {formatCPF(proposalData?.user?.cpf)}</h4>
              {isCpf && <h4>Data de nascimento: {moment(proposalData?.user?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</h4>}
              <h4>E-mail: {proposalData?.user?.email}</h4>
              <h4>Telefone: {formatPhone(proposalData?.user?.phone)}</h4>
              <h4>Endereço: {proposalData?.address?.street}</h4>
              <h4>Número: {proposalData?.address?.number}</h4>
              <h4>Bairro: {proposalData?.address?.district}</h4>
              <h4>Cidade/UF: {proposalData?.address?.city}</h4>
              {proposalData?.address?.complement && <h4>Complemento: {proposalData?.address?.complement}</h4>}
              <h4>CEP: {proposalData?.address?.zip_code}</h4>
            </section>
            <section>
              <h1>Dados do condutor principal</h1>
              <h4>Nome: {proposalData?.maindriver_name || proposalData?.user?.name}</h4>
              <h4>CPF: {formatCPF(proposalData?.maindriver_document || proposalData?.user?.cpf)}</h4>
              {isCpf && (
                <h4>
                  Data de nascimento:{' '}
                  {moment(proposalData?.maindriver_birthdate || proposalData?.user?.birthdate)
                    .utcOffset(0)
                    .format('DD/MM/YYYY')}
                </h4>
              )}
            </section>
            {proposalData?.findPartnerAssistance?.enabled_partner && proposalData?.findPartnerAssistance && (
              <section>
                <h1>Representante/Assessoria</h1>
                <h4>Name: {proposalData?.findPartnerAssistance?.trade_name}</h4>
                <h4>CPF/CNPJ: {proposalData?.findPartnerAssistance?.cnpj}</h4>
                <h4>E-mail: {proposalData?.findPartnerAssistance?.email}</h4>
              </section>
            )}

            {proposalData?.findPartnerAssistance?.enabled_agent && proposalData?.findAgent && !isOceanica && (
              <section>
                <h1>Corretor</h1>
                <h4>Name: {proposalData?.findAgent?.name}</h4>
                <h4>CPF/CNPJ: {proposalData?.findAgent?.cpf}</h4>
              </section>
            )}

            {proposalData?.findPartnerAssistance?.enabled_agent && proposalData?.findAgent && isOceanica && (
              <section>
                <h1>Corretora</h1>
                <h4>Name: {proposalData?.teamAgent?.TB_TEAMS?.name}</h4>
              </section>
            )}

            <section>
              <h1>Dados do veículo</h1>
              <h4>Status: Proposta </h4>
              <h4>Fabricante: {proposalData?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name || '--'}</h4>
              <h4>Modelo: {proposalData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name || '--'}</h4>
              <h4>
                Ano fabricação/ano modelo: {proposalData?.vehicle?.manufacture_year}/{proposalData?.vehicle?.year_model}
              </h4>
              <h4>Codigo Fipe: {proposalData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code}</h4>
              <h4>Chassi: {proposalData?.vehicle?.chassi || '--'}</h4>
              <h4>Placa: {proposalData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(proposalData?.vehicle?.plate)}</h4>
              <h4>Veículo de Leilão: {proposalData?.vehicle?.auction || proposalData?.vehicle?.auction === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Chassis Remarcado/Veiculo Recuperado de Sinistro: {proposalData?.vehicle?.remarked ? 'Sim' : 'Não'}</h4>
              <h4>Uso do veículo: {proposalData?.vehicle?.vehicle_use?.name || '--'} </h4>
              <h4>
                Já possui outro seguro:{' '}
                {proposalData?.vehicle?.vehicle_already_insured || proposalData?.vehicle?.vehicle_already_insured === 1
                  ? 'Sim'
                  : 'Não'}
              </h4>
              <h4>
                Condutores entre 18 e 25 anos:{' '}
                {proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old ||
                proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old === 1
                  ? 'Sim'
                  : 'Não'}
              </h4>
              <h4>0km: {proposalData?.vehicle?.zero_km || proposalData?.vehicle?.zero_km === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Possui GNV: {proposalData?.vehicle?.gnv || proposalData?.vehicle?.gnv === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Valor*: {proposalData?.vehicle?.value && formatCurrency(proposalData?.vehicle?.value)}</h4>
              <small style={{ fontSize: 10 }}>*Valor da Tabela Fipe do veículo na data da cotação</small>
            </section>

            <section>
              <h1>Dados da proposta</h1>
              {/* <h4>Modalidade de Contratação: {proposalData?.policyData?.contracting_modality}</h4> */}
              <h4>Modalidade de Contratação: VMR - Valor de Mercado Referenciado</h4>
              <h4>Fator de Ajuste: {proposalData?.policyData?.adjustment_factor || proposalData?.policyData?.adjustment}%</h4>
              <p style={{ margin: 0, marginTop: 12, fontWeight: 'bold' }}>LIMITE MÁXIMO DE INDENIZAÇÃO (LMI): </p>
              <p style={{ fontSize: 10 }}>
                Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida pelo SEGURADO(A) e expresso na
                apólice.
              </p>
              <p style={{ margin: 0, marginTop: 12, fontWeight: 'bold' }}>PEÇAS E REPAROS: </p>
              <p style={{ fontSize: 10 }}>
                UTILIZAÇÃO DE PEÇAS (NOVAS, USADAS, ORIGINAIS OU NÃO), desde que mantenham a especificação técnica do fabricante.
              </p>
              <p style={{ fontSize: 10 }}>
                *Esta definição não se aplica, caso tenha sido contratada Cobertura adicional de Utilização Exclusiva de Peças
                Genuínas e/ou Originais.
              </p>
              {hasCoverages && (
                <table>
                  <thead>
                    <tr>
                      <td width={200}>Cobertura</td>
                      <td width={200}>Limite Máximo Indenização</td>
                      <td width={200}>Prêmio</td>
                      <td width={200}>Franquias</td>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalData?.arrayUnique
                      ? proposalData?.arrayUnique
                          .filter((item) => item.isService === false)
                          .map((product) => (
                            <tr>
                              <td>{product?.name}</td>
                              <td>{formatCurrency(product?.lmi || '--')}</td>
                              <td>{formatCurrency(product?.value || '--')}</td>
                              <td>{product?.deductible || '--'}</td>
                            </tr>
                          ))
                      : ''}

                    {proposalData?.coverages
                      ? proposalData?.coverages.map((product) => (
                          <tr>
                            <td>{product?.name}</td>
                            <td>{formatCurrency(product?.lmi || '--')}</td>
                            <td>{formatCurrency(product?.value || '--')}</td>
                            <td>{product?.deductible || '--'}</td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                </table>
              )}
              {hasServices && (
                <table>
                  <thead>
                    <tr>
                      <td width={200}>Serviço</td>
                      <td width={200}>Limite Máximo Indenização</td>
                      <td width={200}>Valor</td>
                      <td width={200}>Franquias</td>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalData?.arrayUnique
                      ? proposalData?.arrayUnique
                          .filter((item) => item.isService === true)
                          .map((product) => (
                            <tr>
                              <td>{product?.name}</td>
                              <td>{formatCurrency(product?.lmi || '--')}</td>
                              <td>{formatCurrency(product?.value || '--')}</td>
                              <td>{product?.deductible || '--'}</td>
                            </tr>
                          ))
                      : ''}
                  </tbody>
                </table>
              )}
              <small style={{ fontSize: 10 }}>* Se selecionada a opção de leilão, o fator de ajuste deverá ser de 70% </small>
            </section>

            <section>
              <h1>Demostrativo do prêmio </h1>
              {hasCoverages && (
                <h4 className="valueInfo">
                  Prêmio Líquido Coberturas:{' '}
                  {formatCurrency((proposalData?.isHinova ? proposalData?.valueFinal : valueCoverage) - proposalData?.iof)}
                </h4>
              )}

              <h4 className="valueInfo">IOF: {formatCurrency(proposalData?.iof || 0)}</h4>
              {hasCoverages && (
                <h4 className="valueInfo">Prêmio Total Coberturas: {formatCurrency(proposalData?.valueFinal - valueService)}</h4>
              )}
              {hasServices && <h4 className="valueInfo">Custo Serviços: {formatCurrency(valueService)}</h4>}
              <h4 className="valueInfo">Valor Total Seguro: {formatCurrency(proposalData?.valueFinal || 0)}</h4>
            </section>

            <section style={{ borderBottom: 'none' }}>
              <h1>Resumo das coberturas</h1>
              {proposalData?.arrayUnique
                ? proposalData?.arrayUnique.map((product) => (
                    <>
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              {proposalData?.coverages
                ? proposalData?.coverages.map((product) => (
                    <>
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              <>
                <span className="generalConditionsTitle">VMR - Valor de Mercado Referenciado:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  Esta modalidade de seguro garante, no caso de indenização integral, o pagamento de quantia variável, em moeda
                  corrente nacional, determinada de acordo com a tabela FIPE de referência de cotação para veículo, previamente
                  fixada na proposta do seguro e discriminada na apólice, conjugada com o fator de ajuste (percentual a ser
                  aplicado sobre a tabela no cálculo do valor da indenização), na data da ocorrência do sinistro, aplicado o fator
                  de ajuste contratado e definido na apólice.
                </p>
              </>

              <>
                <span className="generalConditionsTitle">Fator de ajuste:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É o percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que será
                  aplicado sobre o valor que constar no LMI-Limite Máximo de Indenização de cotação para o veículo, para a
                  estipulação do valor da indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar
                  em valor inferior àquele valor do LMI-Limite Máximo de Indenização estabelecido na proposta, de acordo com as
                  características do veículo e seu estado de conservação.
                </p>
              </>

              <>
                <span className="generalConditionsTitle"></span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  A Split Risk privilegia construir relações de longo prazo com seus clientes e parceiros e tem o compromisso da
                  excelência na prestação de serviços, da criatividade na busca de soluções e da transparência na condução dos
                  negócios.
                </p>
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  Portal Sinistros: www.splitrisk.com.br (Comunicar Ocorrência de Sinistro)
                </p>
              </>
            </section>

            <section style={{ borderBottom: 'none' }}>
              <small style={{ fontSize: 10 }}>
                Este seguro é garantido pela Split Risk Seguradora S.A. - CNPJ 43.505.273/0001-09 - Processo SUSEP no
                15414.618377/2020-87, cuja matriz está situada na Avenida Gabriela Junqueira Freitas, número 392, Bairro Jardim
                Patrícia, CEP 38414-126, na cidade de Uberlândia, MG. Reclamações podem ser feitas em www.consumidor.gov.br e para
                deficientes auditivos/fala, ligue para 0800-201-1838. A SUSEP - Superintendência de Seguros Privados - é a
                autarquia federal responsável pela fiscalização, normatização e controle dos mercados de seguro, previdência
                complementar aberta, capitalização, resseguro e corretagem de seguros. O telefone de atendimento gratuito ao
                público da SUSEP é 0800 021 8484 (apenas ligações originadas de telefones fixos).
              </small>
            </section>
          </Printable>
        </Container>
      )}
    </>
  );
}

export default BackofficePrintQuotation;
